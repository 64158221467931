import { create } from 'zustand'
import { Handler } from '@digital-magic/ts-common-utils'
import { CatalogFiltrationForm } from './types'
import { initialCatalogFiltrationForm } from './utils'
import { VehicleSortByEnum, VehicleSortByEnumSchema } from '@api/endpoints/vehicles/types'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { catalogSortByStorageKey } from '@constants/configuration'

export type State = Readonly<{
  catalogFiltrationForm: CatalogFiltrationForm
  sortBy: VehicleSortByEnum
}>

export type Actions = Readonly<{
  setCatalogFiltrationForm: Handler<CatalogFiltrationForm>
  setSortBy: Handler<VehicleSortByEnum>
}>

export const useCatalogParamsStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        catalogFiltrationForm: initialCatalogFiltrationForm,
        setCatalogFiltrationForm: (value) => set(() => ({ catalogFiltrationForm: value })),
        sortBy: VehicleSortByEnumSchema.enum.NEWLY_ADDED,
        setSortBy: (value) =>
          set(() => ({
            sortBy: value
          }))
      }),
      {
        name: catalogSortByStorageKey,
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
)
