import { NumericValuesRange } from '@digital-magic/zod-common-schemas'
import { VehiclesSearchQueryParams } from '@api/endpoints/vehicles'
import { AllValue, AllValueSchema } from '@controls/types'
import { EmptyStringValueSchema } from '@forms/types'
import { CatalogFiltrationForm, FormNumericValuesRange } from './types'

export const initialFormNumericValuesRange: FormNumericValuesRange = {
  from: EmptyStringValueSchema.value,
  to: EmptyStringValueSchema.value
}

export const initialCatalogFiltrationForm: CatalogFiltrationForm = {
  variants: [],
  make: AllValueSchema.value,
  model: AllValueSchema.value,
  fuels: [],
  transmissions: [],
  wheelDrives: [],
  price: initialFormNumericValuesRange,
  powerInKw: initialFormNumericValuesRange,
  year: initialFormNumericValuesRange,
  mileage: initialFormNumericValuesRange,
  engineDisplacementRange: initialFormNumericValuesRange
  // warrantyTypeId: AllValueSchema.value
}

export const toNumericValuesRange = (range: FormNumericValuesRange): NumericValuesRange => ({
  from: range.from === '' ? undefined : range.from,
  to: range.to === '' ? undefined : range.to
})

export const buildQueryParamFromFilterValue = <T extends string | number>(
  value: T | AllValue | undefined
): T | undefined => (value === AllValueSchema.value ? undefined : value)

export const toVehiclesSearchQueryParams = (form: CatalogFiltrationForm): VehiclesSearchQueryParams => ({
  ...form,
  make: buildQueryParamFromFilterValue(form.make),
  model: buildQueryParamFromFilterValue(form.model),
  price: form.price ? toNumericValuesRange(form.price) : undefined,
  powerInKw: form.powerInKw ? toNumericValuesRange(form.powerInKw) : undefined,
  year: form.year ? toNumericValuesRange(form.year) : undefined,
  mileage: form.mileage ? toNumericValuesRange(form.mileage) : undefined,
  engineDisplacementRange: form.engineDisplacementRange ? toNumericValuesRange(form.engineDisplacementRange) : undefined
})
